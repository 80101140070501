<template>
    <div data-app>
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>

      <b-tabs
          active-nav-item-class="nav nav-tabs"
          content-class="mt-3"
      >
        <b-tab title="Customer Vouchers" active>
          <div class="row m-t-40">
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-info text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white">{{ card_summary.totalCardsValue }}</h1>
                  <h6 class="text-white">Total Active Card Value</h6>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-primary text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white"> {{ card_summary.totalRedeemed }}</h1>
                  <h6 class="text-white">Total  Redeemed</h6>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-success text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white"> {{ card_summary.expired }}</h1>
                  <h6 class="text-white">Total Expired</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <hr/>
            </div>
          </div>

          <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
            <div class="col-md-2">
              <label><strong>Filter by status:</strong></label> <br/>
              <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                <select class="form-control" v-model="transaction_status" @change="getVouchersData">
                  <option value="all"> -- Select status --</option>
                  <option value="PENDING"> Pending </option>
                  <option value="EXPIRED"> Expired </option>
                  <option value="ACTIVE"> Active </option>
                </select>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row" style="margin-bottom: 20px;" >
                <div class="col-md-8">
                  <label><strong>Filter by date:</strong></label> <br/>
                  <div class="input-daterange-datepicker input-group" >
                    <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                    <div class="input-group-append">
                      <span class="input-group-text bg-info b-0 text-white">TO</span>
                    </div>
                    <date-picker @input="getVouchersData"   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>

                  </div>

                </div>

                <div class="col-md-4">
                  <div class="input-group mt-2 pull-right">

                    <input type="text" v-model="voucherCode" class="form-control" placeholder="Search by voucher code" aria-label="" aria-describedby="basic-addon1">

                    <div class="input-group-append">
                      <button @click="searchCustomerVoucherData" class="btn btn-primary" type="button">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <a href="/apps/generateVoucher" class="btn btn-success float-right mr-3 ml-3" v-b-modal.generate-voucher>Generate Voucher</a>
              <button class="btn btn-danger float-right" v-b-modal.export-individual-voucher> Export </button>
            </div>
          </div>

          <br/>

          <b-table striped hover :items="vouchers" :fields="columns">
            <template v-slot:cell(transactionId)="data">
              <span v-if="data.item.transactionId"> {{ data.item.transactionId }}</span>
            </template>
            <template v-slot:cell(amount)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>
            <template v-slot:cell(balance)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>

            <template v-slot:cell(netsuiteSaleStatus)="data">
              <span class="badge badge-success" v-if="data.item.netsuiteSaleStatus && data.item.netsuiteSaleStatus == 200">Posted</span>
              <span class="badge badge-warning" v-else>Not Posted</span>
            </template>

            <template v-slot:cell(giftStatus)="data">
                <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">{{ data.value }}</span>
                <span class="badge badge-warning"  v-else-if="data.value == 'PENDING' || data.value == 'PARTIALLY_DEPLETED'" >{{ data.value.replace("-", " ") }}</span>
              <span class="badge badge-danger"  v-else-if="data.value == 'EXPIRED' || data.value == 'DEPLETED'" >{{ data.value }}</span>
            </template>

            <template v-slot:cell(options)="data">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-dropdown text="options">
                <b-dropdown-item  @click="openViewRedemptionsModal(data.item.customerGiftsRedemption)"> View Redemptions </b-dropdown-item>
                <b-dropdown-item  @click="openVoucherExpiryModalModal(data.item, 'Individual')"> Update Voucher Expiry </b-dropdown-item>
                <b-dropdown-item  @click="downloadCustomerVoucher(data.item,'Individual')"> Download Receipt </b-dropdown-item>
                <b-dropdown-item  @click="openResendVoucherCodeToNetsuite(data.item)"> Post to netsuite </b-dropdown-item>

              </b-dropdown>
            </template>
          </b-table>

          <div class="row">
            <div class="col-md-12">

              <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                <ul class="pagination justify-content-center">
                  <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                  <li v-for="page in pagesNumber"
                      v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                  <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab title="Corporate Vouchers">
          <div class="row m-t-40">
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-info text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white">{{ card_summary.totalCardsValue }}</h1>
                  <h6 class="text-white">Total Active Card Value</h6>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-primary text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white"> {{ card_summary.totalRedeemed }}</h1>
                  <h6 class="text-white">Total  Redeemed</h6>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
              <div class="card">
                <div class="box bg-success text-center" style="padding-top: 15px;padding-bottom: 15px;">
                  <h1 class="font-light text-white"> {{ card_summary.expired }}</h1>
                  <h6 class="text-white">Total Expired</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <hr/>
            </div>
          </div>

          <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
            <div class="col-md-2">
              <label><strong>Filter by status:</strong></label> <br/>
              <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                <select class="form-control" v-model="transaction_status" @change="getInstitutionVouchersData">
                  <option value="all"> -- Select status --</option>
                  <option value="PENDING"> Pending </option>
                  <option value="EXPIRED"> Expired </option>
                  <option value="ACTIVE"> Active </option>
                </select>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row" style="margin-bottom: 20px;" >
                <div class="col-md-8">
                  <label><strong>Filter by date:</strong></label> <br/>
                  <div class="input-daterange-datepicker input-group" >
                    <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                    <div class="input-group-append">
                      <span class="input-group-text bg-info b-0 text-white">TO</span>
                    </div>
                    <date-picker   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>

                  </div>

                </div>

                <div class="col-md-4">
                  <button style="margin-top:27px;" type="button" class="btn btn-danger" @click="getInstitutionVouchersData()"><i class="fa fa-search"></i> Filter </button>

                  <!--                  <button style="margin-top:30px;" @click="clearFilter()" class="btn btn-warning">Clear Filter</button>-->
                  <button style="margin-top:27px;" class="btn btn-warning float-right" v-b-modal.export-corporate-vouchers> Export </button>

                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <a href="/apps/generateCorporateVouchers" class="btn btn-success float-right" v-b-modal.generate-voucher>Generate Vouchers</a>
            </div>
          </div>

          <br/>

          <b-table striped hover :items="institutionVouchers" :fields="columnsInstitution">

            <template v-slot:cell(amount)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>
            <template v-slot:cell(balance)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>

            <template v-slot:cell(netsuiteSaleStatus)="data">
              <span class="badge badge-success" v-if="data.item.netsuiteSaleStatus && data.item.netsuiteSaleStatus == 200">Posted</span>
              <span class="badge badge-warning" v-else>Not Posted</span>
            </template>

            <template v-slot:cell(giftStatus)="data">
              <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">{{ data.value }}</span>
              <span class="badge badge-warning"  v-else-if="data.value == 'PENDING' || data.value == 'PARTIALLY_DEPLETED'" >{{ data.value.replace("_", " ") }}</span>
              <span class="badge badge-danger"  v-else-if="data.value == 'EXPIRED' || data.value == 'DEPLETED'" >{{ data.value }}</span>
            </template>

            <template v-slot:cell(options)="data">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-dropdown text="options">
                <b-dropdown-item  @click="openInstitutionVoucherRecipients(data.item.id)"> View Recipients </b-dropdown-item>
                <b-dropdown-item  @click="downloadCustomerVoucher(data.item,'Corporate')"> Download Receipt </b-dropdown-item>
                <!--                <b-dropdown-item v-if="data.item.giftStatus == 'PENDING'"  @click="openApproveInstitutionVoucherDialog(data.item)"> Approve </b-dropdown-item>-->
              </b-dropdown>
            </template>
          </b-table>

          <div class="row">
            <div class="col-md-12">

              <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                <ul class="pagination justify-content-center">
                  <li v-if="paginationInstitutionVouchers.current_page > 1" class="page-item"><a @click.prevent="changePage(paginationInstitutionVouchers.current_page - 1)" class="page-link" href="#">Previous</a></li>
                  <li v-for="page in pagesNumberInstitution"
                      v-bind:class="[ page == isActivedInstitution ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                  <li v-if="paginationInstitutionVouchers.current_page < paginationInstitutionVouchers.last_page"  class="page-item"><a  @click.prevent="changePage(paginationInstitutionVouchers.current_page + 1)"class="page-link" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab title="Voucher Redemptions">
          <b-tabs pills card vertical>
            <b-tab title="Individual Redemptions" active>
              <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
                <div class="col-md-2">
                  <label><strong>By Outlet::</strong></label> <br/>
                  <select class="form-control" v-model="outlet" @change="getVoucherRedemptionsData">
                    <option value="0" > All </option>
                    <option :value="shop.id" v-for="shop in outlets"> {{ shop.name }}</option>
                  </select>
                </div>
                <div class="col-md-9">
                  <div class="row" style="margin-bottom: 20px;" >
                    <div class="col-md-8">
                      <label><strong>Filter by date:</strong></label> <br/>
                      <div class="input-daterange-datepicker input-group" >
                        <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                        <div class="input-group-append">
                          <span class="input-group-text bg-info b-0 text-white">TO</span>
                        </div>
                        <date-picker   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <button style="margin-top:27px;" type="button" class="btn btn-danger" @click="getVoucherRedemptionsData()"><i class="fa fa-search"></i> Filter </button>

<!--                      <button style="margin-top:30px;" @click="clearFilter()" class="btn btn-warning">Clear Filter</button>-->
                      <button style="margin-top:27px;" class="btn btn-success float-right" v-b-modal.export-individual-voucher-redemption> Export </button>

                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <b-table striped hover :items="voucherRedemptions" :fields="voucherRedemptionColumns">
                <template #cell(no)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(customer)="data">
                  <span v-if="data.item.customer">{{ data.item.customer.name }}</span>
                  <span v-if="data.item.voucherCode"> - {{ data.item.voucherCode }} </span>
                </template>

                <template v-slot:cell(outlet)="data">
                  <span v-if="data.item.outlet">{{ data.value.name }}</span>
                </template>
                <template v-slot:cell(redeemedBy)="data">
                  <span v-if="data.item.redeemedBy">{{ data.item.redeemedBy.name }}</span>
                </template>
                <template v-slot:cell(amount)="data">
                  <span>KES. {{ data.value | number('0,0') }}</span>
                </template>

              </b-table>
              <div class="row">
                <div class="col-md-12">

                  <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                    <ul class="pagination justify-content-center">
                      <li v-if="paginationVoucherRedemptions.current_page > 1" class="page-item"><a @click.prevent="changeVoucherRedemptionPage(paginationVoucherRedemptions.current_page - 1)" class="page-link" href="#">Previous</a></li>
                      <li v-for="page in pagesNumberVoucherRedemption"
                          v-bind:class="[ page == isActivedVoucherRedemption ? 'active' : '']" class="page-item"><a @click.prevent="changeVoucherRedemptionPage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                      <li v-if="paginationVoucherRedemptions.current_page < paginationVoucherRedemptions.last_page"  class="page-item"><a  @click.prevent="changeVoucherRedemptionPage(paginationVoucherRedemptions.current_page + 1)"class="page-link" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </b-tab>
            <b-tab title="Corporate Redemptions">
              <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
                <div class="col-md-2">
                  <label><strong>By Outlet:</strong></label> <br/>
                  <select class="form-control" v-model="outlet" @change="getCorporateVoucherRedemptionsData">
                    <option value="0" > All </option>
                    <option :value="shop.id" v-for="shop in outlets"> {{ shop.name }}</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label><strong>By Institution:</strong></label> <br/>
                  <select class="form-control" v-model="institutionId" @change="getCorporateVoucherRedemptionsData">
                    <option value="0" > All </option>
                    <option :value="institution.id" v-for="institution in institutions"> {{ institution.nameOfInstitution }}</option>
                  </select>
                </div>
                <div class="col-md-7">
                  <div class="row" style="margin-bottom: 20px;" >
                    <div class="col-md-8">
                      <label><strong>Filter by date:</strong></label> <br/>
                      <div class="input-daterange-datepicker input-group" >
                        <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                        <div class="input-group-append">
                          <span class="input-group-text bg-info b-0 text-white">TO</span>
                        </div>
                        <date-picker   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <button style="margin-top:27px;" type="button" class="btn btn-danger" @click="getCorporateVoucherRedemptionsData()"><i class="fa fa-search"></i> Filter </button>

<!--                      <button style="margin-top:30px;" @click="clearFilter()" class="btn btn-warning">Clear Filter</button>-->
                      <button style="margin-top:27px;" class="btn btn-success float-right" v-b-modal.export-institution-voucher-redemption> Export </button>

                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <b-table striped hover :items="corporateVoucherRedemptions" :fields="corporateVoucherRedemptionColumns">
                <template #cell(no)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(institution)="data">
                  <span v-if="data.item.institution">{{ data.item.institution.nameOfInstitution }}</span>
                  <span v-if="data.item.voucherCode"> - {{ data.item.voucherCode }} </span>
                </template>

                <template v-slot:cell(outlet)="data">
                  <span v-if="data.item.outlet">{{ data.value.name }}</span>
                </template>
                <template v-slot:cell(redeemedBy)="data">
                  <span v-if="data.item.redeemedBy">{{ data.item.redeemedBy.name }}</span>
                </template>
                <template v-slot:cell(amount)="data">
                  <span>KES. {{ data.value | number('0,0') }}</span>
                </template>

              </b-table>
              <div class="row">
                <div class="col-md-12">

                  <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                    <ul class="pagination justify-content-center">
                      <li v-if="paginationCorporateVoucherRedemptions.current_page > 1" class="page-item"><a @click.prevent="changeCorporateVoucherRedemptionPage(paginationCorporateVoucherRedemptions.current_page - 1)" class="page-link" href="#">Previous</a></li>
                      <li v-for="page in pagesNumberCorporateVoucherRedemption"
                          v-bind:class="[ page == isActivedCorporateVoucherRedemption ? 'active' : '']" class="page-item"><a @click.prevent="changeCorporateVoucherRedemptionPage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                      <li v-if="paginationCorporateVoucherRedemptions.current_page < paginationCorporateVoucherRedemptions.last_page"  class="page-item"><a  @click.prevent="changeCorporateVoucherRedemptionPage(paginationCorporateVoucherRedemptions.current_page + 1)"class="page-link" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </b-tab>
          </b-tabs>

        </b-tab>
        <b-tab title="Gift Registry">
          <div class="row mb-3">
            <div class="col-md-12">
              <button  class="btn btn-warning float-right" v-b-modal.export-gift-registry> Export </button>
            </div>
          </div>
          <b-table striped hover :items="registries" :fields="columnsGiftRegistry">
            <template v-slot:cell(options)="data">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-dropdown text="options">
                <b-dropdown-item  @click="openViewGiftRegistryContributionModal(data.item.giftContributions)"> View Contributions </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Outlet Vouchers">
          <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
            <div class="col-md-2">
              <label><strong>Filter by outlet:</strong></label> <br/>
              <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                <select class="form-control" v-model="voucherOutletId" @change="getOutletVoucherCodesData">
                  <option value="0"> -- Select --</option>
                  <option :value="outlet.id" v-for="outlet in outlets">  {{ outlet.name }} </option>
                </select>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row" style="margin-bottom: 20px;" >
                <div class="col-md-8">
                  <label><strong>Filter by date:</strong></label> <br/>
                  <div class="input-daterange-datepicker input-group" >
                    <date-picker   :lang="lang" v-model="fromDateVoucherCode" valueType="format" :first-day-of-week="1"></date-picker>

                    <div class="input-group-append">
                      <span class="input-group-text bg-info b-0 text-white">TO</span>
                    </div>
                    <date-picker   :lang="lang" v-model="toDateVoucherCode" valueType="format" :first-day-of-week="1"></date-picker>

                  </div>

                </div>

                <div class="col-md-4">
                  <button style="margin-top:30px;" type="button" class="btn btn-danger" @click="getOutletVoucherCodesData()"><i class="fa fa-search"></i> Filter </button>

                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <b-button class="btn btn-danger float-right" v-b-modal.import-voucher-code>Import Voucher Codes</b-button>
            </div>
          </div>

          <b-table striped hover :items="outletVouchers" :fields="outletVoucherColumns">
            <template v-slot:cell(amount)="data">
              KES <span>{{ data.value }}</span>
            </template>

            <template v-slot:cell(outlet)="data">
               <span>{{ data.item.outlet.name }}</span>
            </template>

            <template v-slot:cell(status)="data">
              <span  class="badge badge-success"  v-if="data.value == 'UTILISED'">{{ data.value }}</span>
              <span class="badge badge-warning"  v-else-if="data.value == 'NOT_UTILISED'">{{ data.value.replace("_", " ") }}</span>
            </template>

            <template v-slot:cell(options)="data">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-dropdown text="options">
                <b-dropdown-item  @click="openVoucherExpiryModalModal(data.item, 'Outlet Vouchers')"> Update Voucher Expiry </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="row">
            <div class="col-md-12">

              <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                <ul class="pagination justify-content-center">
                  <li v-if="paginationVoucherCodes.current_page > 1" class="page-item"><a @click.prevent="changePageVoucherCodes(paginationVoucherCodes.current_page - 1)" class="page-link" href="#">Previous</a></li>
                  <li v-for="page in pagesNumberVoucherCodes"
                      v-bind:class="[ page == isActivedVoucherCodes ? 'active' : '']" class="page-item"><a @click.prevent="changePageVoucherCodes(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                  <li v-if="paginationVoucherCodes.current_page < paginationVoucherCodes.last_page"  class="page-item"><a  @click.prevent="changePageVoucherCodes(paginationVoucherCodes.current_page + 1)"class="page-link" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab title="Parking Vouchers">

          <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
            <div class="col-md-2">
              <label><strong>Filter by status:</strong></label> <br/>
              <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                <select class="form-control" v-model="voucherOutletId" @change="getParkingVouchersData">
                  <option value="0"> -- Select --</option>
                  <option :value="outlet.id" v-for="outlet in outlets">  {{ outlet.name }} </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label><strong>Filter by status:</strong></label> <br/>
              <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                <select class="form-control" v-model="transaction_status" @change="getParkingVouchersData">
                  <option value="all"> -- Select status --</option>
                  <option value="PENDING"> Pending </option>
                  <option value="EXPIRED"> Expired </option>
                  <option value="ACTIVE"> Active </option>
                </select>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row" style="margin-bottom: 20px;" >
                <div class="col-md-8">
                  <label><strong>Filter by date:</strong></label> <br/>
                  <div class="input-daterange-datepicker input-group" >
                    <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                    <div class="input-group-append">
                      <span class="input-group-text bg-info b-0 text-white">TO</span>
                    </div>
                    <date-picker @input="getParkingVouchersData"   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>
                  </div>

                </div>

                <div class="col-md-4">

                </div>
              </div>
            </div>
            <div class="col-md-3 mt-2">
              <a href="/apps/generateParkingVoucher" class="btn btn-success float-right mr-3 ml-3" >Generate Voucher</a>
              <button class="btn btn-danger float-right" v-b-modal.export-individual-voucher> Export </button>
            </div>
          </div>

          <br/>
          <b-table striped hover :items="parking_vouchers" :fields="parking_voucher_columns">
            <template v-slot:cell(transactionId)="data">
              <span v-if="data.item.transactionId"> {{ data.item.transactionId }}</span>
            </template>
            <template v-slot:cell(amount)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>
            <template v-slot:cell(balance)="data">
              <span>KES. {{ data.value | number('0,0') }}</span>
            </template>

            <template v-slot:cell(parkingDuration)="data">
              <span v-if="data.item.parkingVoucherPaymentType === 'POSTPAID'">KES. {{ data.value | number('0,0') }}</span>
            </template>

            <template v-slot:cell(parkingRedemptionStatus)="data">
              <span class="badge badge-success" v-if="data.item.parkingRedemptionStatus == 'REDEEMED'">Redeemed</span>
              <span class="badge badge-warning" v-else>Pending</span>
            </template>

            <template v-slot:cell(giftStatus)="data">
              <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">{{ data.value }}</span>
              <span class="badge badge-warning"  v-else-if="data.value == 'PENDING' || data.value == 'PARTIALLY_DEPLETED'" >{{ data.value.replace("-", " ") }}</span>
              <span class="badge badge-danger"  v-else-if="data.value == 'EXPIRED' || data.value == 'DEPLETED'" >{{ data.value }}</span>
            </template>

            <template v-slot:cell(options)="data">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-dropdown text="options">
                <b-dropdown-item  @click="openVoucherExpiryModalModal(data.item, 'Individual')"> Update Voucher Expiry </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="row">
            <div class="col-md-12">


              <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                <ul class="pagination justify-content-center">
                  <li v-if="paginationParkingVouchers.current_page > 1" class="page-item"><a @click.prevent="changePageParkingVouchers(paginationParkingVouchers.current_page - 1)" class="page-link" href="#">Previous</a></li>
                  <li v-for="page in pagesNumberParkingVouchers"
                      v-bind:class="[ page == isActivedParkingVouchers ? 'active' : '']" class="page-item"><a @click.prevent="changePageParkingVouchers(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                  <li v-if="paginationParkingVouchers.current_page < paginationParkingVouchers.last_page"  class="page-item"><a  @click.prevent="changePageParkingVouchers(paginationParkingVouchers.current_page + 1)"class="page-link" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-modal id="import-voucher-code" title="Import Voucher Codes" @ok="saveImportedFile"   centered>
        <div class="mt-5 mb-5">

          <div class="form-group">
            <label>
              Outlet:
            </label> <br/>
            <select class="form-control" v-model="voucherOutletId" @change="getOutletVoucherCodesData">
              <option value="0"> -- Select --</option>
              <option :value="outlet.id" v-for="outlet in outlets">  {{ outlet.name }} </option>
            </select>
          </div>
          <div class="form-group">
            <label>
              Excel File:
            </label> <br/>
            <input type="file" ref="voucherCodeFile" @change="handleFileUpload"  />
            <a  href="/OutletVouchersTemplate.xlsx">Download Sample File</a>
          </div>
        </div>

      </b-modal>
      <b-modal size="lg" id="view-redemptions" title="Redemptions" @cancel="true"   centered>
        <table class="table table-bordered">
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Outlet</th>
            <th>Cashier</th>
            <th>Netsuite Status</th>
          </tr>
          <tr v-for="redemption in redemptions">
            <td >{{redemption.created}}</td>
            <td >{{redemption.amount}}</td>
            <td >
              <span v-if="redemption.outlet">{{redemption.outlet.name}}</span>
            </td>
            <td >
              <span v-if="redemption.redeemedBy">{{redemption.redeemedBy.name}} </span>
            </td>
            <td >{{redemption.netsuiteVoucherPost}}</td>
          </tr>
        </table>
      </b-modal>
      <b-modal size="lg" id="view-egift-registry" title="Gift Registry Contributions" @cancel="true"   centered>
        <table class="table table-bordered">
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
          <tr v-for="contribution in contributions">
            <td >{{contribution.created}}</td>
            <td >{{contribution.name}}</td>
            <td >{{contribution.amount}}</td>
            <td >{{contribution.phoneNo}}</td>
            <td >{{contribution.status}}</td>
          </tr>
        </table>
      </b-modal>
      <b-modal id="update-voucher-expiry" title="Update Voucher Expiry" @cancel="true" @ok="submitVoucherExpiry"   centered>
        <form>
          <div class="form-group">
            <label>Expiry Date:</label> <br/>
            <date-picker   :lang="lang" v-model="voucherExpiry.expiryDate" valueType="format" :first-day-of-week="1"></date-picker>
          </div>
        </form>
      </b-modal>
      <b-modal id="export-individual-voucher" title="Export Customer Vouchers" @cancel="true" @ok="exportIndividualVouchersToExcel"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Please enter your email address:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="email_address" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal id="export-institution-voucher-redemption" title="Export Institution Voucher Redemption" @cancel="true" @ok="exportInstitutionVoucherRedemptionsToExcel"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Please enter your email address:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="email_address" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal id="export-individual-voucher-redemption" title="Export Customer Voucher Redemption" @cancel="true" @ok="exportIndividualVoucherRedemptionsToExcel"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Please enter your email address:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="email_address" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal id="export-gift-registry" title="Export Gift Registry" @cancel="true" @ok="exportGiftRegistriesToExcel"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Please enter your email address:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="email_address" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal id="export-corporate-vouchers" title="Export Corporate Vouchers" @cancel="true" @ok="exportCorporateVouchersToExcel"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Please enter your email address:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="email_address" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    require('vue-toastr/src/vue-toastr.scss');
    import { mapGetters, mapActions } from "vuex";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import CodeInput from "vue-verification-code-input";
    import vSelect from "vue-select";
    import Table from "@/views/table/bs-table/Table.vue";

    export default {
        name: 'gift-cards',
        data: () => ({

          email_address:"",
          voucherExpiry:{
            voucherId:"",
            expiryDate:"",
            voucherType:""
          },
          redemptions:[],
          voucherRedemptions:[],
          contributions:[],
          voucherRedemptionColumns:["no","transactionDate","customer","amount","redeemedBy","status","outlet"],
          corporateVoucherRedemptionColumns:["no","transactionDate","institution","amount","redeemedBy","status","outlet"],
          fileUpload:"",
            voucherOutletId:0,
            customers:[],
            transaction_status:"all",
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range'
                }
            },
            card_summary:{
              totalCardsValue:"15,000",
              totalRedeemed:"5000",
              expired:"10,000"
             },
            table_loading:true,
            tickets:"",
            total_tickets:0,
            total_tickets_today:0,
            total_tickets_this_week:0,
            approval_decline_comments:"",
            from_date:"all",
            to_date:"all",
            fromDateVoucherCode:"all",
            toDateVoucherCode:"all",
            search_phone_number:"",
            rowsPerPageItems: [50, 100, 150, 200],
            selected: [],
            users:[],
            columns:[{
              key: 'created',
              label: 'Date',
              sortable: false
            },"boughtBy","recipient","amount","balance","voucherCode","paymentMode",{
              key: 'giftStatus',
              label: 'Status',
              sortable: false
            },{
              key: 'netsuiteSaleStatus',
              label: 'Netsuite Status',
              sortable: false
            },"expiryDate","transactionId","Options"],
          parking_voucher_columns:[{
            key: 'created',
            label: 'Date',
            sortable: false
          },
            {
              key: 'boughtBy',
              label: 'Generated By',
              sortable: false
            },
            ,"recipient",
              "parkingVoucherPaymentType",
            {
              key: 'prepaidVoucherParkingAmount',
              label: 'Prepaid amount',
              sortable: false
            },
            {
              key: 'parkingDuration',
              label: 'Duration (Minutes)',
              sortable: false
            },
            "voucherCode",
            "parkingRedemptionStatus",
            "outlet",
            {
              key: 'giftMessage',
              label: 'Purpose',
              sortable: false
            }
            ,{
            key: 'giftStatus',
            label: 'Status',
            sortable: false
          },"expiryDate","Options"],
          columnsGiftRegistry:["created","endDate","customerName","code","goal","contributions","url","status","options"],
          columnsInstitution:[{
            key: 'created',
            label: 'Date',
            sortable: false
          },"boughtBy","amount","balance",{
            key: 'netsuiteSaleStatus',
            label: 'Netsuite Status',
            sortable: false
          },{
            key: 'giftStatus',
            label: 'Status',
            sortable: false
          },"expiryDate","Options"],
          institutionVouchers:[],
          outletVoucherColumns:[{
            key: 'created',
            label: 'Date',
            sortable: false
          },"voucherCode","outlet","amount","expiryDate", "status","Options"],
          registries:[],
          outletVouchers:[],
          unutilisedVouchers:[],
            isLoading: false,
            fullPage: true,
            loading:false,
            voucherStatus:"all",
            vouchers: [],
            parking_vouchers: [],
             outlets:[],
             outlet:0,
            csrf: "",

            lottery:"",
            pagination: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            paginationParkingVouchers: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            paginationVoucherRedemptions: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offset: 4,
            paginationCorporateVoucherRedemptions:{
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            paginationVoucherCodes: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            offsetVoucherCodes: 4,
            voucher:{
                voucherType:"sarit",
                existingCustomer:"yes",
                name:"",
                phoneNumber:"",
                emailAddress:"",
                message:"",
                redemptionOption:"any",
                outlets:[],
                amount:"",
                voucherCode:"",
                generateVoucherCode:"",
                mpesaPhoneNumber:"",
                paymentOption:"Mpesa",
                customerId:"",
                voucherOutletId:""
            },
            offsetInstitutionVouchers: 4,
            offsetVoucherRedemption:4,
            paginationInstitutionVouchers: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            voucherCode: "",
            institutionId:0,
            institutions:[],
            corporateVoucherRedemptions:[]
        }),
        mounted() {
          this.getVouchersData();
          this.getVoucherStatsData();
          this.getOutletsData();
          this.getOutletVoucherCodesData();
          this.getInstitutionVouchersData();
          this.getGiftRegistriesData();
          this.getVoucherRedemptionsData();
          this.getCorporateVoucherRedemptionsData()
          this.getInstitutionalCustomersData();
          this.getParkingVouchersData()
        },
        methods:{
          ...mapActions(["resendVoucherToNetsuite","downloadCustomerVoucherReceipt","exportCorporateVouchers","exportGiftRegistries","exportInstitutionVoucherRedemptions","exportIndividualVoucherRedemptions","exportIndividualVouchers","getInstitutionalCustomers","getCorporateVoucherRedemptions","searchCustomerVouchers","getVoucherRedemptions","updateVoucherExpiryDate","getGiftRegistries","approveInstitutionVoucher","getInstitutionalVouchers","getVouchers", "getOutlets","getVoucherStats","searchCustomerByPhoneNumber","getOutletVoucherCodes","importVoucherCodes"]),

          downloadCustomerVoucher(customerVoucher, customerVoucherType){
            let self = this;

            self.isLoading = true;

            this.downloadCustomerVoucherReceipt({voucherId: customerVoucher.id, voucherType:customerVoucherType})
                .then(function (voucher) {

                  self.isLoading = false;

                  if(voucher.statusCode == 200){
                    window.open(voucher.message, '_blank');
                  } else {
                    self.$bvToast.toast(voucher.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });
                  }

                })
                .catch(function (error) {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });
                })
          },
          exportIndividualVouchersToExcel(){

            let self = this;
            this.isLoading = true;

            let outlet_id = 0;

            if(this.outlet){
              outlet_id = this.outlet.id;
            }

            this.exportIndividualVouchers({startDate:this.from_date,endDate:this.to_date, emailAddress: this.email_address})
                .then(function () {

                  self.isLoading = false;

                  self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
                .catch(function (error) {

                  self.isLoading = false;

                  console.log(error);
                });
          },
          exportCorporateVouchersToExcel(){

            let self = this;
            this.isLoading = true;

            let outlet_id = 0;

            if(this.outlet){
              outlet_id = this.outlet.id;
            }

            this.exportCorporateVouchers({startDate:this.from_date,endDate:this.to_date, emailAddress: this.email_address})
                .then(function () {

                  self.isLoading = false;

                  self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
                .catch(function (error) {

                  self.isLoading = false;

                  console.log(error);
                });
          },

          exportGiftRegistriesToExcel(){

            let self = this;
            this.isLoading = true;

            let outlet_id = 0;

            if(this.outlet){
              outlet_id = this.outlet.id;
            }

            this.exportGiftRegistries({ emailAddress: this.email_address})
                .then(function () {

                  self.isLoading = false;

                  self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
                .catch(function (error) {

                  self.isLoading = false;

                  console.log(error);
                });
          },

          exportInstitutionVoucherRedemptionsToExcel(){

            let self = this;
            this.isLoading = true;

            let outlet_id = 0;

            if(this.outlet){
              outlet_id = this.outlet.id;
            }

            this.exportInstitutionVoucherRedemptions({startDate:this.from_date,endDate:this.to_date, emailAddress: this.email_address})
                .then(function () {

                  self.isLoading = false;

                  self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
                .catch(function (error) {

                  self.isLoading = false;

                  console.log(error);
                });
          },

          exportIndividualVoucherRedemptionsToExcel(){

            let self = this;
            this.isLoading = true;

            let outlet_id = 0;

            if(this.outlet){
              outlet_id = this.outlet.id;
            }

            this.exportIndividualVoucherRedemptions({startDate:this.from_date,endDate:this.to_date, emailAddress: this.email_address})
                .then(function () {

                  self.isLoading = false;

                  self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
                .catch(function (error) {

                  self.isLoading = false;

                  console.log(error);
                });
          },

          getInstitutionalCustomersData() {
            let self = this;

            this.getInstitutionalCustomers()
                .then(function (institutions) {
                  self.institutions = institutions;

                })
                .catch(function (error) {

                })
          },
          searchCustomerVoucherData() {
            let self = this;
            this.isLoading = true;
            this.searchCustomerVouchers({voucherCode: this.voucherCode})
                .then(function (vouchers) {
                  self.isLoading = false;
                  self.vouchers = vouchers;
                })
                .catch(function (error) {
                  self.isLoading = false;
                })
          },
          getVoucherRedemptionsData(){
            let self = this;

            this.getVoucherRedemptions({outletId: this.outlet, startDate: this.from_date, endDate: this.to_date, page: this.paginationVoucherRedemptions.current_page})
                .then(function (vouchers) {

                  self.voucherRedemptions = vouchers.content;
                  self.paginationVoucherRedemptions.current_page = vouchers.number;
                  self.paginationVoucherRedemptions.total = vouchers.totalElements;
                  self.paginationVoucherRedemptions.per_page = vouchers.numberOfElements;
                  self.paginationVoucherRedemptions.from = vouchers.pageable.offset + 1 ;
                  self.paginationVoucherRedemptions.to = vouchers.pageable.pageSize;
                  self.paginationVoucherRedemptions.last_page = vouchers.totalPages;

                })
                .catch(function (error) {


                })
          },
          getCorporateVoucherRedemptionsData(){
            let self = this;

            this.getCorporateVoucherRedemptions({outletId: this.outlet, startDate: this.from_date, endDate: this.to_date, page: this.paginationCorporateVoucherRedemptions.current_page, customerId: this.institutionId})
                .then(function (vouchers) {

                  self.corporateVoucherRedemptions = vouchers.content;
                  self.paginationCorporateVoucherRedemptions.current_page = vouchers.number;
                  self.paginationCorporateVoucherRedemptions.total = vouchers.totalElements;
                  self.paginationCorporateVoucherRedemptions.per_page = vouchers.numberOfElements;
                  self.paginationCorporateVoucherRedemptions.from = vouchers.pageable.offset + 1 ;
                  self.paginationCorporateVoucherRedemptions.to = vouchers.pageable.pageSize;
                  self.paginationCorporateVoucherRedemptions.last_page = vouchers.totalPages;

                })
                .catch(function (error) {


                })
          },
          handleFileUpload(){
            this.fileUpload = this.$refs.voucherCodeFile.files[0];
          },
          openViewRedemptionsModal(redemptions) {
            this.redemptions = redemptions;
            this.$bvModal.show("view-redemptions")
          },
          openVoucherExpiryModalModal(voucher, voucherType) {
            this.voucherExpiry.voucherId = voucher.id;
            this.voucherExpiry.voucherType = voucherType;
            this.$bvModal.show("update-voucher-expiry")
          },
          openViewGiftRegistryContributionModal(contributions) {
            this.contributions = contributions;
            this.$bvModal.show("view-egift-registry")
          },
          openInstitutionVoucherRecipients(voucherId) {
            this.$router.push("/apps/institutionVoucherRecipients/"+voucherId);
          },
          openResendVoucherCodeToNetsuite(voucher) {
            this.voucher = voucher;

            let self = this;
            this.$bvModal.msgBoxConfirm('Re-post voucher: '+voucher.voucherCode +' sale to netsuite?')
                .then(value => {

                  if(value){
                    self.confirmVoucherRepostingToNetsuite();
                  }

                })
                .catch(err => {
                  // An error occurred
                })
          },
          openApproveInstitutionVoucherDialog(voucher) {
            this.voucher = voucher;

            let self = this;
            this.$bvModal.msgBoxConfirm('Approve voucher for '+voucher.boughtBy +' ?')
                .then(value => {

                  if(value){
                    self.confirmInstitutionVoucherPurchase();
                  }

                })
                .catch(err => {
                  // An error occurred
                })
          },
          submitVoucherExpiry() {
            this.isLoading = true;

            let self = this;

            this.updateVoucherExpiryDate( this.voucherExpiry).then(() => {
              self.isLoading = false;

              self.$bvToast.toast("Voucher expiry updated", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              if(self.voucherExpiry.voucherType == "Individual") {
                self.getVouchersData();
              } else if(self.voucherExpiry.voucherType == "Institution") {
                self.getInstitutionVouchersData();
              } else if(self.voucherExpiry.voucherType == "Outlet Vouchers") {
                self.getOutletVoucherCodesData();
              }
            })
                .catch(error => {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
          },
          confirmVoucherRepostingToNetsuite() {
            this.isLoading = true;

            let self = this;

            this.resendVoucherToNetsuite( this.voucher).then(() => {
              self.isLoading = false;

              self.$bvToast.toast("Voucher posted to netsuite", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

            })
                .catch(error => {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
          },
          confirmInstitutionVoucherPurchase() {
            this.isLoading = true;

            let self = this;

            this.approveInstitutionVoucher( this.voucher).then(() => {
              self.isLoading = false;

              self.$bvToast.toast("Voucher approved", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              self.getInstitutionVouchersData();

            })
                .catch(error => {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                })
          },
          saveImportedFile(){
            let self = this;

            if(this.fileUpload){
              this.isLoading = true;

              let formData = new FormData();
              formData.append('excelFile', this.fileUpload);
              formData.append('outletId', this.voucherOutletId);

              this.importVoucherCodes(formData).then(() => {
                self.isLoading = false;

                self.voucherOutletId = 0;

                self.$bvToast.toast("Voucher codes imported successfully.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getOutletVoucherCodesData();

                //self.getOutletData();

              })
                  .catch(error => {

                    console.log(error)

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })

            }
          },
          searchCustomer(){
            let self = this;
            this.isLoading = true;

            this.searchCustomerByPhoneNumber({phone_number: this.voucher.phoneNumber})
                .then(function (customers) {
                  self.isLoading = false;
                  self.customers = customers;
                })
                .catch(function (error) {
                })
          },
          changePage: function (page) {
            this.pagination.current_page = page;
            this.getVouchersData();
          },
          changePageParkingVouchers: function (page) {
            this.paginationParkingVouchers.current_page = page;
            this.getParkingVouchersData();
          },
          changeVoucherRedemptionPage: function (page) {
            this.paginationVoucherRedemptions.current_page = page;
            this.getVoucherRedemptionsData();
          },
          changeCorporateVoucherRedemptionPage: function (page) {
            this.paginationCorporateVoucherRedemptions.current_page = page;
            this.getCorporateVoucherRedemptionsData();
          },
          changePageVoucherCodes: function (page) {
            this.paginationVoucherCodes.current_page = page;
            this.getOutletVoucherCodesData();
          },
          getVouchersByOutlet() {
            let self = this;
            this.isLoading = true;

            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:"all",toDate:"all", outletId:this.voucher.voucherOutletId, status: "NOT_UTILISED"})
                .then(function (outletVouchers) {
                  self.isLoading = false;
                  self.unutilisedVouchers = outletVouchers.content;

                })
                .catch(function (error) {
                  self.isLoading = false;

                })
          },
          getOutletVoucherCodesData() {
            let self = this;
            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:this.fromDateVoucherCode,toDate:this.toDateVoucherCode, outletId:this.voucherOutletId, status: this.voucherStatus})
                .then(function (outletVouchers) {
                  self.outletVouchers = outletVouchers.content;
                  self.paginationVoucherCodes.current_page = outletVouchers.number;
                  self.paginationVoucherCodes.total = outletVouchers.totalElements;
                  self.paginationVoucherCodes.per_page = outletVouchers.numberOfElements;
                  self.paginationVoucherCodes.from = outletVouchers.pageable.offset + 1 ;
                  self.paginationVoucherCodes.to = outletVouchers.pageable.pageSize;
                  self.paginationVoucherCodes.last_page = outletVouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getParkingVouchersData(){
            let self = this;
            this.getVouchers({page: this.paginationParkingVouchers.current_page, from_date:this.from_date,to_date:this.to_date,
              status:this.transaction_status, voucherType : "PARKING", outletId: this.voucherOutletId})
                .then(function (vouchers) {
                  self.parking_vouchers = vouchers.content;
                  self.paginationParkingVouchers.current_page = vouchers.number;
                  self.paginationParkingVouchers.total = vouchers.totalElements;
                  self.paginationParkingVouchers.per_page = vouchers.numberOfElements;
                  self.paginationParkingVouchers.from = vouchers.pageable.offset + 1 ;
                  self.paginationParkingVouchers.to = vouchers.pageable.pageSize;
                  self.paginationParkingVouchers.last_page = vouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getVouchersData(){
            let self = this;
            this.isLoading = true;
            this.getVouchers({page: this.pagination.current_page, from_date:this.from_date,to_date:this.to_date,
              status:this.transaction_status, outletId: 0, voucherType : "NORMAL"})
              .then(function (vouchers) {
                self.isLoading = false;
                self.vouchers = vouchers.content;
                self.pagination.current_page = vouchers.number;
                self.pagination.total = vouchers.totalElements;
                self.pagination.per_page = vouchers.numberOfElements;
                self.pagination.from = vouchers.pageable.offset + 1 ;
                self.pagination.to = vouchers.pageable.pageSize;
                self.pagination.last_page = vouchers.totalPages;
              })
              .catch(function (error) {
                console.log(error);
                self.isLoading = false;
              })
          },
          getGiftRegistriesData(){
            let self = this;
            this.getGiftRegistries()
                .then(function (registries) {
                  self.registries = registries;

                })
                .catch(function (error) {

                })
          },
          getInstitutionVouchersData(){
            let self = this;
            this.getInstitutionalVouchers({page: this.paginationInstitutionVouchers.current_page, from_date:this.from_date,to_date:this.to_date, status:this.transaction_status})
                .then(function (vouchers) {
                  self.institutionVouchers = vouchers.content;
                  self.paginationInstitutionVouchers.current_page = vouchers.number;
                  self.paginationInstitutionVouchers.total = vouchers.totalElements;
                  self.paginationInstitutionVouchers.per_page = vouchers.numberOfElements;
                  self.paginationInstitutionVouchers.from = vouchers.pageable.offset + 1 ;
                  self.paginationInstitutionVouchers.to = vouchers.pageable.pageSize;
                  self.paginationInstitutionVouchers.last_page = vouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getVoucherStatsData(){
            let self = this;
            this.getVoucherStats({from_date:this.from_date,to_date:this.to_date})
              .then(function (voucherStats) {
                self.card_summary = voucherStats;

              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
          },
            clearFilter(){
                location.replace("/apps/vouchers");
            },
            viewTickets(tickets){
                this.tickets = tickets;

                this.$refs.viewTickets.open();
            },
            getOutletsData(){
              let self = this;

              this.getOutlets()
                  .then(function (outlets) {
                    self.outlets = outlets;

                  })
                  .catch(function (error) {
                    if( typeof error.response.status != "undefined"){
                      if(error.response.status == 401){
                        location.replace("/login");
                      }

                    }
                  })
            }
        },
        computed: {
          isActived: function () {
            return this.pagination.current_page + 1;
          },
          pagesNumber: function () {
            if (!this.pagination.to) {
              return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
              to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          isActivedParkingVouchers: function () {
            return this.paginationParkingVouchers.current_page + 1;
          },
          pagesNumberParkingVouchers: function () {
            if (!this.paginationParkingVouchers.to) {
              return [];
            }
            var from = this.paginationParkingVouchers.current_page - this.offset;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.paginationParkingVouchers.last_page) {
              to = this.paginationParkingVouchers.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          isActivedVoucherCodes: function () {
            return this.paginationVoucherCodes.current_page + 1;
          },
          pagesNumberVoucherCodes: function () {
            if (!this.paginationVoucherCodes.to) {
              return [];
            }
            var from = this.paginationVoucherCodes.current_page - this.offsetVoucherCodes;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offsetVoucherCodes * 2);
            if (to >= this.paginationVoucherCodes.last_page) {
              to = this.paginationVoucherCodes.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          isActivedInstitution: function () {
            return this.paginationInstitutionVouchers.current_page + 1;
          },
          pagesNumberInstitution: function () {
            if (!this.paginationInstitutionVouchers.to) {
              return [];
            }
            var from = this.paginationInstitutionVouchers.current_page - this.offsetInstitutionVouchers;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offsetInstitutionVouchers * 2);
            if (to >= this.paginationInstitutionVouchers.last_page) {
              to = this.paginationInstitutionVouchers.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          isActivedVoucherRedemption: function () {
            return this.paginationVoucherRedemptions.current_page + 1;
          },
          pagesNumberVoucherRedemption: function () {
            if (!this.paginationVoucherRedemptions.to) {
              return [];
            }
            var from = this.paginationVoucherRedemptions.current_page - this.offsetVoucherRedemption;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offsetVoucherRedemption * 2);
            if (to >= this.paginationVoucherRedemptions.last_page) {
              to = this.paginationVoucherRedemptions.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          isActivedCorporateVoucherRedemption: function () {
            return this.paginationCorporateVoucherRedemptions.current_page + 1;
          },
          pagesNumberCorporateVoucherRedemption: function () {
            if (!this.paginationCorporateVoucherRedemptions.to) {
              return [];
            }
            var from = this.paginationCorporateVoucherRedemptions.current_page - this.offsetVoucherRedemption;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offsetVoucherRedemption * 2);
            if (to >= this.paginationCorporateVoucherRedemptions.last_page) {
              to = this.paginationCorporateVoucherRedemptions.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          }
        },
        components:{
          Table,
          vSelect,
          CodeInput,
          FormWizard,
          TabContent,
          Loading,
          DatePicker
        }
    }
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#4AB5E6 !important;
}


.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -67px;
  color:#4AB5E6;
  font-weight: 600;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 20px;
  width: 20px;
  background-color: #4AB5E6;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:14px;
  left:15.5%;
  width: 69.5% !important;
  background-color: #4AB5E6 !important;
  height: 2px !important;
}
table tr th{
  padding-top: 10px;
  padding-bottom: 10px;
}
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label.form-label {
  font-size: 14px;
  font-weight: 700;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #4AB5E6 !important;
  color:#000 !important;
  border: none;
  margin-top: 20px;

}

table td{
  padding: 10px;
}
</style>


